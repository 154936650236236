import Vue from 'vue'
import Intercom from '@intercom/messenger-js-sdk'

export default function({ app: { router }, store }, inject) {

  // Determine if user is logged in
  const isLoggedIn = (auth) => {
    if (!auth || !auth.account || !auth.account.promoterOid) {
      return false
    }

    return true
  }

  // Get and set properties of logged in user (i.e company name, email address etc)
  // It allows us to add user properties to Intercom
  const setUserProperties = (auth) => {
    const account = auth?.account || {}
    return {
      oid: account.oid || '',
      promoterOid: account.promoterOid || '',
      companyName: account.companyName || '',
      emailAddress: account.emailAddress || '',
      firstName: account.firstName || '',
      lastName: account.lastName || '',
    }
  }

  // Make sure that pageviews are captured with each route change
  router.afterEach(to => {
    const {
      auth
    } = store._modules.root.state

    // Ensure user is logged in
    if (!isLoggedIn(auth))
      return

    let userProperties = setUserProperties(auth)

    Intercom({
      app_id: process.env.arIntercomFinAppId,
      user_id: userProperties.oid,
      name: userProperties.firstName + ' ' + userProperties.lastName,
      email: userProperties.emailAddress,
      company: {
        company_id: userProperties.promoterOid,
        name: userProperties.companyName
      }
    })
  })
}
